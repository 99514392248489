import React, { useEffect, useContext, useState, useRef } from "react";
import styled from "styled-components";
import calender from "../../../../assets/images/calendar.svg";
import { Context } from "../../../contexts/Store";
import $ from "jquery";
import ButtonLoader from "../../../genaral/Loader/ButtonLoader";
import { accountsConfig } from "../../../../axiosConfig";
import SectionLoader from "../../../helpers/SectionLoader";
import { faIR } from "date-fns/locale";

function CreateCampusModal({
    isModal,
    setModal,
    setEvent,
    editModal,
    campusPk,
    setEditModal,
    setReload,
    setSuccessful,
}) {
    const [campusName, setCampusName] = useState("");
    // const [campusCode, setCampusCode] = useState("");
    const [subArea, setSubArea] = useState("");
    const [campusType, setCampusTypeBody] = useState("");
    const [Description, setDescription] = useState("");
    const [contactPersonName, setcontactPersonName] = useState("");

    const [contactPersonPhone, setcontactPersonPhone] = useState("");

    const [contactPersonEmail, setcontactPersonEmail] = useState("");
    const [isButtonLoading, setButtonLoading] = useState(false);
    const [isDropdown, setDropdown] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [file1, setFile1] = useState(null);
    const [file2, setFile2] = useState(null);
    const [loading, setLoading] = useState(false);
    const [localBodieLists, setLocalBodieLists] = useState([]);
    const [selectedBodie, setSelectedBodie] = useState("");
    const [localInput, setLocalInput] = useState("");
    const [searchInput, setSearchInput] = useState("");

    const [campusNameError, setCampusNameError] = useState("");
    // const [campusCodeError, setCampusCodeError] = useState("");
    const [selectedBodieError, setSelectedBodieError] = useState("");
    const [searchQuery, setSearchQuery] = useState("");
    const [showOptions, setShowOptions] = useState(false);
    const [campusData, setCampusData] = useState([]);
    const [domain, setDomain] = useState("");
    const [gstin, setGstin] = useState("");
    const [isModalLoder, setSetModalLoader] = useState(false);
    const [defaultSuffix, setDefaultSuffix] = useState(
        ".techatschool.steyp.com"
    );
    const [isSuffixActive, setIsSuffixActive] = useState(false);

    const handleFile1Change = (event) => {
        setFile1(event.target.files[0]);
    };

    const handleFile2Change = (event) => {
        setFile2(event.target.files[0]);
    };

    const handleSelectChange = (e) => {
        setSelectedBodie(e.target.value);
    };

    const handleInputChange = (e) => {
        setSearchInput(e.target.value);
    };

    const handleCampusNameChange = (e) => {
        setCampusName(e.target.value);
    };

    // const handleCampusCodeChange = (e) => {
    //     setCampusCode(e.target.value);
    // };

    const handleModal = () => {
        setCampusName("");
        // setCampusCode("");
        setSubArea("");
        setSelectedBodie("");
        setCampusTypeBody("");
        setDescription("");
        setcontactPersonName("");
        setcontactPersonPhone("");
        setcontactPersonEmail("");
        setModal(false);
        // setEditModal(false);
        setFile1(null);
        setFile2(null);
        setSearchQuery("");
        setDomain("");
        setGstin("");
        // setReload((prev) => !prev);
    };

    // handle suffix

    const handleSuffixClick = () => {
        if (domain.endsWith(defaultSuffix)) {
            const newDomain = domain.slice(0, -defaultSuffix.length);
            setDomain(newDomain);
            setIsSuffixActive(false);
        } else {
            const newDomain = domain + defaultSuffix;
            setDomain(newDomain);
            setIsSuffixActive(true);
        }
    };

    const {
        state: {
            user_data: { access_token },
        },
    } = useContext(Context);

    useEffect(() => {
        if (isModal) {
            $("html").addClass("modal-enabled");
        } else {
            $("html").removeClass("modal-enabled");
        }
    }, [isModal]);

    useEffect(() => {
        accountsConfig
            .get(`general/chief/localbodies/?q=${campusType}`, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setLocalBodieLists(data);
                } else if (StatusCode === 6001) {
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    useEffect(() => {
        if (editModal) {
            setSetModalLoader(true);
            accountsConfig
                .get(`api/v1/users/campus/get/${campusPk}`, {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                })
                .then((response) => {
                    const { StatusCode, data } = response.data;
                    if (StatusCode === 6000) {
                        const {
                            local_body,
                            contact_person_name,
                            contact_person_phone,
                            contact_person_email,
                            // token_code,
                            description,
                            name,
                            logo,
                            photo,
                            domain,
                            gstin,
                        } = data;

                        setCampusData(data);

                        if (local_body) {
                            setSearchQuery(local_body.name);
                            setSelectedBodie(local_body.pk);
                        }

                        setcontactPersonName(contact_person_name);
                        setcontactPersonPhone(contact_person_phone);
                        setcontactPersonEmail(contact_person_email);
                        // setCampusCode(token_code);
                        setDescription(description);
                        setCampusName(name);
                        setDomain(domain);
                        setGstin(gstin);
                        if (logo) {
                            setFile1({ name: logo });
                        }
                        if (photo) {
                            setFile2({ name: photo });
                        }
                        setSetModalLoader(false);
                    } else {
                        setSetModalLoader(false);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    setSetModalLoader(false);
                });
        }
    }, [editModal, isModal]);

    const handleSubmit = () => {
        if (editModal) {
            updateCampus();
        } else {
            createEvent();
        }
    };
    const updateCampus = () => {
        const formData = new FormData();
        formData.append("name", campusName);
        formData.append("logo", file1);
        formData.append("photo", file2);
        formData.append("local_body", selectedBodie);
        formData.append("description", Description);
        // formData.append("token_code", campusCode?.toLocaleUpperCase());
        formData.append("contact_person_name", contactPersonName);
        formData.append("contact_person_phone", contactPersonPhone);
        formData.append("contact_person_email", contactPersonEmail);
        formData.append("gstin", gstin);
        formData.append("domain", domain);
        setButtonLoading(true);

        accountsConfig
            .post(
                `api/v1/users/campus/update/${campusPk}/`,
                formData,

                {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                }
            )
            .then((response) => {
                const { StatusCode, data, message } = response.data;
                if (StatusCode === 6000) {
                    setReload(true);
                    // setReload((prev) => !prev);
                    handleModal();
                    setButtonLoading(false);
                    setSuccessful(true);
                } else {
                    setErrorMessage(message.message);
                    setButtonLoading(false);
                }
            })
            .catch((error) => {
                console.log(error);
                setButtonLoading(false);
            });
    };
    const createEvent = () => {
        const formData = new FormData();
        formData.append("name", campusName);
        formData.append("local_body", selectedBodie);
        formData.append("description", Description);
        // formData.append("token_code", campusCode.toLocaleUpperCase());
        formData.append("contact_person_name", contactPersonName);
        formData.append("contact_person_phone", contactPersonPhone);
        formData.append("contact_person_email", contactPersonEmail);
        formData.append("gstin", gstin);
        formData.append("domain", domain);

        if (file1) {
            formData.append("logo", file1);
        }
        if (file2) {
            formData.append("photo", file2);
        }
        setButtonLoading(true);

        // Validation logic

        if (!campusName) {
            setCampusNameError("Campus name is required");
        } else {
            setCampusNameError("");
        }
        // if (!campusCode) {
        //     setCampusCodeError("Campus code is required");
        // } else {
        //     setCampusCodeError("");
        // }

        if (campusName)
            accountsConfig
                .post(
                    `/api/v1/users/campus/create-new-campus/`,
                    formData,

                    {
                        headers: {
                            Authorization: `Bearer ${access_token}`,
                        },
                    }
                )
                .then((response) => {
                    const { StatusCode, data, message } = response.data;
                    if (StatusCode === 6000) {
                        setButtonLoading(false);
                        setEvent(true);
                        handleModal();
                        setSuccessful(true);
                        setReload(true);
                    } else {
                        setButtonLoading(false);
                        setErrorMessage(message.message);
                        setEvent(false);
                    }
                })
                .catch((error) => {
                    setButtonLoading(false);
                    console.log(error);
                });
        else {
            setButtonLoading(false);
        }
    };

    function useOutsideClick(ref, ModalState) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    ModalState(false);
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    const wrapperRef = useRef(null);
    const LocalRef = useRef(null);
    useOutsideClick(wrapperRef, setModal);
    useOutsideClick(LocalRef, setShowOptions);

    const filteredLocalBodies = localBodieLists.filter((local) =>
        local.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <BackContainer>
            {isModal ? (
                <Overlay
                    onClick={() => {
                        setModal(false);
                        setReload(true);
                    }}
                    style={{ display: isModal ? "block" : "none" }}
                ></Overlay>
            ) : (
                ""
            )}
            <Cover style={{ transform: isModal && "scale(1,1)" }}>
                <Modal>
                    <Container loader={isModalLoder}>
                        {isModalLoder ? (
                            <SectionLoader />
                        ) : (
                            <>
                                <Top>
                                    <Head>
                                        <Headtitle>
                                            {editModal
                                                ? "Edit Campus Details"
                                                : "Add campus"}
                                        </Headtitle>
                                        <Closeicon onClick={handleModal}>
                                            <img
                                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/05-04-2022/close.png"
                                                alt="Icon"
                                            />
                                        </Closeicon>
                                    </Head>
                                </Top>
                                {/* encType="multipart/form-data" */}

                                <DetailContainer ref={LocalRef}>
                                    <CampusName>Local Body</CampusName>
                                    <CampusNameinput
                                        placeholder="Search Here"
                                        value={searchQuery}
                                        onChange={(e) => {
                                            setSearchQuery(e.target.value);
                                            setShowOptions(true);
                                        }}
                                        onClick={() =>
                                            setShowOptions((prev) => !prev)
                                        }
                                    />
                                    {showOptions && (
                                        <OptionsContainer active={showOptions}>
                                            {filteredLocalBodies.length > 0 ? (
                                                filteredLocalBodies.map(
                                                    (local) => (
                                                        <Option
                                                            key={local.id}
                                                            value={local.id}
                                                            onClick={() => {
                                                                setSearchQuery(
                                                                    local.name
                                                                );
                                                                setShowOptions(
                                                                    false
                                                                );
                                                                setSelectedBodie(
                                                                    local.id
                                                                );
                                                            }}
                                                        >
                                                            {local.name}
                                                        </Option>
                                                    )
                                                )
                                            ) : (
                                                <Option value="" disabled>
                                                    No Local Bodies Found
                                                </Option>
                                            )}
                                        </OptionsContainer>
                                    )}
                                    <Error className="error">
                                        {selectedBodieError}
                                    </Error>
                                </DetailContainer>
                                {/* <CampusDetails>
                            <CampusName>Campus type</CampusName>
                            <CampusNameinput
                                type="text"
                                placeholder="Select campus type"
                                onChange={(e) =>
                                    setCampusTypeBody(e.target.value)
                                }
                            />
                        </CampusDetails> */}
                                <CampusDetails>
                                    <CampusName className="RequireField">
                                        Campus name *
                                    </CampusName>
                                    <CampusNameinput
                                        type="text"
                                        placeholder="Enter campus name"
                                        onChange={handleCampusNameChange}
                                        value={campusName}
                                    />
                                    <Error className="error">
                                        {campusNameError}
                                    </Error>
                                </CampusDetails>
                                <CampusDetails>
                                    <CampusName>Description</CampusName>
                                    <CampusNameinput
                                        type="text"
                                        placeholder="Description"
                                        value={Description}
                                        onChange={(e) =>
                                            setDescription(e.target.value)
                                        }
                                    />
                                </CampusDetails>
                                <DetailContainer>
                                    <CampusDetailsDiv
                                        onClick={() =>
                                            document
                                                .getElementById("photo")
                                                .click()
                                        }
                                    >
                                        <CampusName>Campus image</CampusName>
                                        <CampusNameinputFile>
                                            <input
                                                id="photo"
                                                className="file"
                                                type="file"
                                                onChange={handleFile2Change}
                                            />

                                            {file2
                                                ? file2.name.length > 22
                                                    ? `${file2.name.substring(
                                                          0,
                                                          22
                                                      )}...${file2.name
                                                          .split(".")
                                                          .pop()}`
                                                    : file2.name
                                                : "Choose Image"}
                                        </CampusNameinputFile>
                                    </CampusDetailsDiv>
                                    <CampusDetailsDiv
                                        onClick={() =>
                                            document
                                                .getElementById("logo")
                                                .click()
                                        }
                                    >
                                        <CampusName>Campus logo</CampusName>
                                        <CampusNameinputFile>
                                            <input
                                                id="logo"
                                                className="file"
                                                type="file"
                                                onChange={handleFile1Change}
                                            />
                                            {file1
                                                ? file1.name.length > 22
                                                    ? `${file1.name.substring(
                                                          0,
                                                          22
                                                      )}...${file1.name
                                                          .split(".")
                                                          .pop()}`
                                                    : file1.name
                                                : "Choose Image"}
                                        </CampusNameinputFile>
                                    </CampusDetailsDiv>
                                    <CampusDetailsDiv>
                                        <CampusName>
                                            Contact person name
                                        </CampusName>
                                        <CampusNameinput
                                            type="text"
                                            placeholder="Enter name"
                                            value={contactPersonName}
                                            onChange={(e) =>
                                                setcontactPersonName(
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </CampusDetailsDiv>
                                    <CampusDetailsDiv>
                                        <CampusName>
                                            Contact person phone
                                        </CampusName>
                                        <CampusNameinput
                                            type="number"
                                            placeholder="Enter phone number"
                                            value={contactPersonPhone}
                                            onChange={(e) =>
                                                setcontactPersonPhone(
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </CampusDetailsDiv>

                                    <CampusDetailsDiv>
                                        <CampusName>
                                            Contact person email
                                        </CampusName>
                                        <CampusNameinput
                                            type="email"
                                            placeholder="Enter email"
                                            value={contactPersonEmail}
                                            onChange={(e) =>
                                                setcontactPersonEmail(
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </CampusDetailsDiv>
                                    {/* <CampusDetailsDiv>
                                        <CampusName className="RequireField">
                                            Campus code *
                                        </CampusName>
                                        <CampusNameinput
                                            type="text"
                                            placeholder="Enter code"
                                            onChange={handleCampusCodeChange}
                                            value={campusCode}
                                        />
                                        <Error className="error">
                                            {errorMessage ? errorMessage : ""}
                                        </Error> */}
                                    {/* <Error className="error">
                                            {campusCodeError}
                                        </Error> */}
                                    {/* </CampusDetailsDiv> */}

                                    <CampusDetailsDiv>
                                        <CampusName>GSTIN</CampusName>
                                        <CampusNameinput
                                            type="text"
                                            placeholder="Enter gstin"
                                            onChange={(e) =>
                                                setGstin(e.target.value)
                                            }
                                            value={gstin}
                                        />
                                    </CampusDetailsDiv>
                                </DetailContainer>

                                <CampusDetailsDiv>
                                    <CampusName>Domain</CampusName>
                                    <CampusNameinput
                                        type="text"
                                        placeholder="Enter domain"
                                        onChange={(e) => {
                                            setDomain(e.target.value);
                                        }}
                                        value={domain}
                                    />
                                    <SuffixDiv
                                        onClick={handleSuffixClick}
                                        className={
                                            isSuffixActive ? "active" : ""
                                        }
                                    >
                                        <Suffix>.techatschool.steyp.com</Suffix>
                                    </SuffixDiv>
                                </CampusDetailsDiv>

                                <CreateButtonBox>
                                    <CancelButton onClick={handleModal}>
                                        Cancel
                                    </CancelButton>
                                    <CreateButton
                                        style={{
                                            cursor: campusName
                                                ? "pointer"
                                                : "not-allowed",
                                        }}
                                        onClick={handleSubmit}
                                    >
                                        {isButtonLoading ? (
                                            <ButtonLoader />
                                        ) : editModal ? (
                                            "Update"
                                        ) : (
                                            "Create"
                                        )}
                                    </CreateButton>
                                </CreateButtonBox>
                            </>
                        )}
                    </Container>
                </Modal>
            </Cover>
        </BackContainer>
    );
}

export default CreateCampusModal;

const SelectOption = styled.option``;

const SelectDiv = styled.select`
    width: 100%;
    color: #fff;
    padding: 10px;
    border: 1px solid #26272a;
    background-color: #161619 !important;
    border-radius: 5px !important;
`;

const CampusNames = styled.div`
    border-radius: 5px;
    padding: 10px 20px;
    transition: 0.3s ease all;
    border: 1px solid transparent;
    cursor: pointer;
    p {
        color: #fff;
    }
    &.active {
        background-color: #161619;
        border: 1px solid #6b6b6b;
    }
    &:hover {
        background-color: #333;
    }
`;

const Cover = styled.div`
    position: fixed;
    transition: 0.3s;
    transform: scale(0, 0);
    width: 100%;
    height: 100vh;
    z-index: 1000;
    left: 0;
    top: 0px;
`;

const BackContainer = styled.div``;
const Overlay = styled.div`
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(2px);
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0px;
    width: 100%;
    z-index: 1000;
    min-height: 100vh;
    max-height: 100vh;
    filter: blur(1px);
`;
const Modal = styled.div`
    width: 90%;
    max-width: 640px;
    max-height: 80vh;
    position: absolute;
    margin: 0 auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: 0.5s;
    z-index: 101;
    border: 1px solid #26272a;
    background: #1b1c1f;
    border-radius: 5px;
    overflow-y: hidden;
    box-shadow: 0px 3px 56px #00000048;
    overflow-y: scroll;
    &::-webkit-scrollbar {
        display: none;
    }

    @media all and (max-width: 1280px) {
        max-height: 75vh;
        overflow-y: scroll;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    @media all and (max-width: 400px) {
        max-height: 65vh;
        overflow-y: scroll;
        &::-webkit-scrollbar {
            display: none;
        }
    }
`;
const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 40px 35px;
    width: 100%;
    height: ${({ loader }) => (loader ? "70vh" : "100%")};
    @media all and (max-width: 768px) {
        padding: 30px;
    }
    @media all and (max-width: 480px) {
        width: 500px;
        overflow-x: scroll;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    @media all and (max-width: 360px) {
        padding: 10px;
    }
`;
const Top = styled.div`
    border-bottom: 2px solid #26272a;
`;
const Head = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
`;
const Headtitle = styled.p`
    font-size: 18px;
    color: #fff;
    font-family: gordita_medium;
`;
const Closeicon = styled.div`
    width: 18px;
    cursor: pointer;
    img {
        width: 100%;
        display: block;
    }
`;
const CampusDetails = styled.div`
    width: 100%;
`;
const CampusDetailsDiv = styled.div`
    width: 48%;
`;
const CampusName = styled.p`
    margin-bottom: 5px;
    color: #fff;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-size: 14px;
`;
const CampusNameinput = styled.input`
    width: 100%;
    color: #fff;
    padding: 10px;
    border: 1px solid #26272a;
    background-color: #161619 !important;
    border-radius: 5px !important;
    cursor: pointer;
    &::placeholder {
        color: #4f5360;
        font-size: 14px;
        font-family: gordita_medium;
        font-weight: lighter;
        font-style: normal;
    }
`;
const CampusNameinputFile = styled.div`
    width: 100%;
    color: #fff;
    padding: 10px;
    border: 1px solid #26272a;
    background-color: #161619 !important;
    border-radius: 5px !important;
    cursor: pointer;
    font-size: 14px;
    font-family: "gordita_medium";
    color: #fff;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &::placeholder {
        color: #4f5360;
        font-size: 14px;
        font-family: gordita_medium;
        font-weight: lighter;
        font-style: normal;
    }

    input {
        font-size: 14px;
        font-family: "gordita_medium";
        color: #fff;
    }

    & input {
        display: none;
    }
`;
const Error = styled.p`
    font-size: 12px;
    color: #ff3333;
    margin-left: 2px;
`;
const Middle = styled.div`
    margin: 20px 0px;
`;

const CreateButton = styled.button`
    background: #fe673a;
    color: #fff;
    border-radius: 5px !important;
    width: 120px;
    height: 40px;
    justify-content: center;
    display: flex;
    font-size: 14px;
    align-items: center;
    cursor: pointer;
    font-family: "gordita_medium";
`;
const CreateButtonBox = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    @media all and (max-width: 480px) {
        margin-top: 10px;
    }
`;
const Campus = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    width: 100%;
    @media all and (max-width: 480px) {
        flex-direction: column;
        & :first-child {
            margin-top: 0;
        }
    }
`;
const Campusfield = styled.div`
    width: 50%;
    position: relative;
    margin-right: 15px;
    :last-child {
        margin-right: 0px;
    }
    @media all and (max-width: 480px) {
        width: 100%;
        :last-child {
            margin-top: 20px;
        }
    }
    p.error {
        position: absolute;
        color: red;
        font-size: 12px;
        left: 0;
    }
`;
const SelectCampustittle = styled.p`
    font-size: 14px;
    font-family: "gordita_regular";
    color: #fff;
    margin-bottom: 5px;
`;
const SelectCampusInput = styled.input`
    background-color: #161619 !important;
    border: 1px solid #26272a;
    border-radius: 5px !important;
    width: 100%;
    padding: 10px;
    color: #fff;
    ::-webkit-calendar-picker-indicator {
        display: block;
        background: url(${calender}) no-repeat;
        width: 15px;
        background-size: cover;
    }
`;
const CancelButton = styled.div`
    color: #fff;
    border-radius: 5px !important;
    width: 120px;
    height: 40px;
    justify-content: center;
    display: flex;
    font-size: 14px;
    align-items: center;
    cursor: pointer;
    font-family: "gordita_medium";
    margin-right: 20px;
    border: 1px solid #fe673a;
`;
const DetailContainer = styled.form`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 12px;
`;
const OptionsContainer = styled.div`
    font-size: 14px;
    position: absolute;
    z-index: 1;
    background-color: #1b1c1f;
    border: 1px solid #26272a;
    border-radius: 5px;
    width: 50%;
    max-height: 242px;
    overflow-y: auto;
    margin-top: 80px; /* Adjust this to control the spacing between input and options */
    display: ${(props) => (props.active ? "block" : "none")};
    &::-webkit-scrollbar {
        width: 8px;
    }
    &::-webkit-scrollbar-track {
        background: #161619;
        border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb {
        background: #fe673a;
        border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: #e95e2b;
    }
`;

const Option = styled.option`
    padding: 8px 12px;
    cursor: pointer;
    color: #fff;
`;
const SuffixDiv = styled.div`
    cursor: pointer;
    font-size: 14px;
    border: 1px solid #26272a;
    width: fit-content;
    padding: 6px 10px;
    border-radius: 6px;
    margin-top: 8px;
    color: #fff;
    &.active {
        box-shadow: 0 0 5px #fe673a;
        transition: box-shadow 0.3s ease-in-out;
        color: #fe673a;
        transition: color 0.3s ease;
    }
`;
const Suffix = styled.span`
    font-size: 14px;
`;
